import React from "react";
import { graphql } from "gatsby";
import moment from "moment";
import Layout from "./layout";
import SEO from "./seo";
import * as katex from "katex";

const PostShowItem = ({ data }) => {
    const post = data.markdownRemark;
    const re = /<katex>(.+)<\/katex>/g;
    let html = post.html;

    var xArray;

    while ((xArray = re.exec(post.html))) {
        const tex = xArray[1];
        const parsed = katex.renderToString(tex, {
            displayMode: true,
            throwOnError: false,
            macros: { "’": "'" }
        });
        console.log(tex, parsed);

        html = html.replace(xArray[0], `<div class="equation">${parsed}</div>`);
    }

    return (
        <Layout>
            <SEO title={post.frontmatter.title} />

            <div className="post p2 p-responsive wrap" role="main">
                <div className="measure">
                    <div className="post-header mb2">
                        <h1 className={`m0`}>{post.frontmatter.title}</h1>

                        <h4 className="h4 mt0">{post.frontmatter.subtitle}</h4>

                        <span className="post-meta">
                            {moment(post.frontmatter.date).format("Mo MMM Y")} (
                            {moment(post.frontmatter.date).fromNow()})
                        </span>
                        <br />

                        <span className="post-meta small">
                            {post.timeToRead} minute{post.timeToRead > 1 ? "s" : ""} read
                        </span>
                    </div>

                    <article className="post-content" dangerouslySetInnerHTML={{ __html: html }} />

                    {/*<hr>*/}

                    {/*<div className='cloud'>*/}
                    {/*  <a style='font-size: 70%' href='/tags.html#web-design'>web-design</a>*/}
                    {/*<a style='font-size: 133%' href='/tags.html#live-music'>live-music</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#shopping'>shopping</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#gig'>gig</a>*/}
                    {/*<a style='font-size: 170%' href='/tags.html#funny'>funny</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#programming'>programming</a>*/}
                    {/*<a style='font-size: 133%' href='/tags.html#music'>music</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#sport-climbing'>sport-climbing</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#javascript'>javascript</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#lucky'>lucky</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#only-in-china'>only-in-china</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#colour'>colour</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#acoustic-guitar'>acoustic-guitar</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#bolting'>bolting</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#liuzhou'>liuzhou</a>*/}
                    {/*<a style='font-size: 170%' href='/tags.html#home-recording'>home-recording</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#guilin'>guilin</a>*/}
                    {/*<a style='font-size: 70%' href='/tags.html#yangshuo'>yangshuo</a>*/}

                    {/*</div>*/}
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                title
                subtitle
                date
            }
            timeToRead
        }
    }
`;

export default PostShowItem;
